require('./includes/photoswipe');
let scroll;

window.sendOnlyOnce = function sendOnlyOnce(el, idForm) {
    el.disabled = true;
    if (document.getElementById(idForm)) {
        document.getElementById(idForm).submit();
    }
    setTimeout(function() {
        el.disabled = false
    }, 5000);
};


require([
    'smooth-scroll/dist/smooth-scroll.polyfills.min'
], function (SmoothScroll) {
    scroll = new SmoothScroll('a[href*="#"]', {
        ignore: '#product-list',
        speed: 500,
        speedAsDuration: true,
        updateURL: false,
        popstate: false,
        topOnEmptyHash: true
    });
});

//HEADROOM
require([
    'headroom.js/dist/headroom'
], function (Headroom) {
// grab an element
    var myElement = document.getElementById("header-wrapper");
// construct an instance of Headroom, passing the element
    var headroom = new Headroom(myElement);
// initialise
    headroom.init();
});

//Lazyload init
new MiniLazyload({
    rootMargin: "0px 0px -10px 0px",
    threshold: 0
}, "[data-lazyload]");



let slideUp = (target, duration = 500) => {
    target.classList.add('toggling');
    target.classList.remove('opened');
    target.classList.add('closed');
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        target.classList.remove('toggling');
    }, duration);
};

let slideDown = (target, duration = 500) => {
    target.classList.add('toggling');
    target.classList.remove('closed');
    target.classList.add('opened');
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;

    if (display === 'none')
        display = 'block';

    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        target.classList.remove('toggling');
    }, duration);
};

let slideToggle = (target, duration = 500) => {
    if(window.getComputedStyle(target).height) {
        if (window.getComputedStyle(target).display === 'none') {
            if(!target.classList.contains('toggling')) {
                return slideDown(target, duration);
            }
        } else {
            if(!target.classList.contains('toggling')) {
                return slideUp(target, duration);
            }
        }
    }
};


// UP ARROW
function scrollUpToggle() {
    if (window.pageYOffset > 400) {
        document.getElementById("up").classList.add('active');
    } else {
        document.getElementById("up").classList.remove('active');
    }
}

document.getElementById("up").addEventListener("click", function () {
    scroll.animateScroll(0);
});

//COOKIES ACCEPT
document.getElementById("js-acceptCookies").addEventListener("click", function (e) {
    e.preventDefault();
    document.getElementById("cookies-bar").classList.add('active');
});


//BURGER MENU
const header = document.getElementById('header-wrapper');
const burgerMenu = document.getElementById('burger-menu');
const sidebarOverlay = document.getElementById('sidebar-overlay');
const burgerMenuClose = document.getElementById("burger-menu-close");
const sidebar = document.getElementById("sidebar");

function checkOpenBurger() {
    if (header.classList.contains("open")) {
        header.classList.remove('open');
        burgerMenu.classList.remove('open');
    }
}

function toggleSidebar() {
    sidebar.classList.toggle('open');
    sidebarOverlay.classList.toggle('open');
}

if (sidebarOverlay) {
    sidebarOverlay.addEventListener("click", function () {
        toggleSidebar();
    });
}

document.getElementById("nav-icon").addEventListener("click", function () {
    if (header.classList.contains("open")) {
        header.classList.remove('open');
        burgerMenu.classList.remove('open');
    } else {
        header.classList.add('open');
        burgerMenu.classList.add('open');
    }
});

document.getElementById("burger-menu-close").addEventListener("click", function () {
    checkOpenBurger();
});

if (burgerMenuClose) {
    burgerMenuClose.addEventListener("click", function () {
        checkOpenBurger();
    });
}

function checkHashInURL() {
    if (location.hash) {
        if (document.querySelector(location.hash) !== null) {
            if (window.pageYOffset > 0) {
                header.classList.remove('headroom--pinned');
                header.classList.add('headroom--unpinned');
            }
        }
    }
}

//Floated banner on homepage show delay
let floatedBanner = document.getElementsByClassName('banner-floated')[0];
if (floatedBanner !== undefined) {
    setTimeout(function () {
        floatedBanner.classList.add('active');
    }, 1000);
}

//Scroll arrow home banner animation
let scrollArrow = document.getElementsByClassName('scroll-down')[0];
if (scrollArrow !== undefined) {
    setTimeout(function () {
        scrollArrow.classList.add('active');
    }, 2000);
}

//Sidebar Slide Toggle child categories
document.querySelectorAll('.category-parent').forEach(parent => {
    if(parent.classList.contains('active')) {
        // slideDown(parent.querySelector("ul"), 400);
        parent.querySelector("ul").style.display = 'block';
    }
});

document.querySelectorAll('.js-category-parent-icon').forEach(icon => {
    icon.addEventListener('click', function (e) {
        if(!this.parentElement.classList.contains('active')) {
            this.parentElement.classList.add('active');
            slideDown(this.parentElement.querySelector("ul"), 400);
        }
        else {
            this.parentElement.classList.remove('active');
            slideUp(this.parentElement.querySelector("ul"), 400);
        }
    });
});

document.querySelectorAll('.js-toggle-extra-options').forEach(element => {
    element.addEventListener('click', function (e) {
        if(this.type === "checkbox") {
            if (this.checked === true) {
                slideDown(this.closest(".cart-delivery-extra").querySelector(".cart-delivery-extra-item"), 300);
            }
            else {
                slideUp(this.closest(".cart-delivery-extra").querySelector(".cart-delivery-extra-item"), 300);
            }
        }
    });
});

document.querySelectorAll('.js-toggle-exta').forEach(element => {
    element.addEventListener('click', function (e) {
        this.classList.toggle('active');
        slideToggle(this.nextElementSibling, 300);
    });
});

//Sidebar toggle mobile
let sidebarToggle = document.getElementById("sidebar-toggle");
if (sidebarToggle !== null) {
    sidebarToggle.addEventListener("click", function () {
        toggleSidebar();
    });
}

document.querySelectorAll('.js-searchToggle').forEach(element => {
    element.addEventListener('click', function (e) {
        this.parentNode.classList.toggle('active');
        header.classList.toggle('active');
        /*
        if( this.parentNode.classList.contains('active')) {
            this.parentNode.querySelector(".js-searchInput").focus();
        }
        else {
            this.parentNode.querySelector(".js-searchInput").blur();
        }
        */
    });
});

document.querySelectorAll('.alert').forEach(element => {
    let current = element;
    setTimeout(function () {
        current.classList.add('showed');
    },500);
    setTimeout(function () {
        current.classList.remove('showed');
    },4500);
});

document.querySelectorAll('.alert-close').forEach(element => {
    element.addEventListener('click', function (e) {
        element.parentElement.classList.remove('showed');
    });
});

(function () {
    scrollUpToggle();

    window.onload = checkHashInURL;
    window.onscroll = scrollUpToggle;
})();



