// Photoswipe
function sizeCalc(width, height) {
    if(width > screen.width || height > screen.width ) {
        return {width: width, height: height};
    }
    const aspectRatio = ( width / height );
    if( (screen.width - width) < (screen.height - height)) {
        const newWidth = screen.width - 50;
        return {width: newWidth, height: ( newWidth / aspectRatio )};
    }
    else {
        const newHeight =  screen.height - 50;
        return {width: newHeight * aspectRatio, height: newHeight};
    }
}


require([
    'photoswipe/dist/photoswipe.min',
    'photoswipe/dist/photoswipe-ui-default.min'
], function( PhotoSwipe, PhotoSwipeUI_Default ) {
    const pswpElement = document.querySelectorAll('.pswp')[0];
    // build items array
    const images = document.getElementsByClassName('lightbox');
    const items = [];
    for (let i = 0; i < images.length; i++) {
        let image = images[i];
        let sizes = sizeCalc(image.children[0].naturalWidth, image.children[0].naturalHeight);
        items.push(
            {
                mediumImage: {
                    src: (image).getAttribute('href') ? (image).getAttribute('href') : "",
                    title: (image).getAttribute('data-caption') ? (image).getAttribute('data-caption') : "",
                    w: sizes.width,
                    h: sizes.height,
                },
                originalImage: {
                    src: (image).getAttribute('href') ? (image).getAttribute('href') : "",
                    title: (image).getAttribute('data-caption') ? (image).getAttribute('data-caption') : "",
                    w: sizes.width * 2,
                    h: sizes.height * 2,
                }
        });
        (image).onclick = function(event) {
            event.preventDefault();
            const options = {
                index: i,
                bgOpacity: 0.85,
                showHideOpacity: true,
                shareButtons: [
                    {id:'facebook', label:'Facebook', url:'https://www.facebook.com/sharer/sharer.php?u='+image.children[0].getAttribute('src')},
                    {id:'download', label:'Stiahnuť', url: 'data:'+image.children[0].getAttribute('src'), download:true}
                ],
            };
            const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);

            var realViewportWidth,
                useLargeImages = false,
                firstResize = true,
                imageSrcWillChange;
            gallery.listen('beforeResize', function() {
                realViewportWidth = gallery.viewportSize.x * window.devicePixelRatio;
                if(useLargeImages && realViewportWidth < 1000) {
                    useLargeImages = false;
                    imageSrcWillChange = true;
                } else if(!useLargeImages && realViewportWidth >= 1000) {
                    useLargeImages = true;
                    imageSrcWillChange = true;
                }
                // Invalidate items only when source is changed and when it's not the first update
                if(imageSrcWillChange && !firstResize) {
                    gallery.invalidateCurrItems();
                }

                if(firstResize) {
                    firstResize = false;
                }

                imageSrcWillChange = false;

            });

            gallery.listen('gettingData', function(index, item) {
                if( useLargeImages ) {
                    item.src = item.originalImage.src;
                    item.w = item.originalImage.w;
                    item.h = item.originalImage.h;
                    item.title = item.originalImage.title;
                } else {
                    item.src = item.mediumImage.src;
                    item.w = item.mediumImage.w;
                    item.h = item.mediumImage.h;
                    item.title = item.mediumImage.title;
                }
            });
            gallery.init();
        }
    }
});


